import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StudyProgressIcon } from "../../Common/assets/Icons";
import StudyCard from "../Cards/StudyCard";
import { CustomCircularProgressWithLabel } from "../../Common/UI/ProgressWithLabel";
import StatsCards from "../Cards/StatsCards";
import { title } from "process";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";

const DashboardContent = () => {
  const [loading, setLoading] = useState(true);
  const [visitData, setVisitData] = useState<any[]>([]);
  const [statsData, setStatsData] = useState<any>({});

  const { id: studyId } = useParams();

  useEffect(() => {
    const fetchPhaseData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/study/${studyId}/dashboard`
        );
        const data = res.data?.data;

        const newData = [
          {
            id: 1,
            value: data?.totalQueries,
            title: "Queries",
            desc: "Total number of queries",
            color: "#FFCA54",
          },
          {
            id: 2,
            value: data?.totalOpenQueries,
            title: "Open Queries",
            desc: "Total number of queries",
            color: "#9DA0C4",
          },
          {
            id: 3,
            value: data?.totalParticipants,
            title: "Signed Inclusions",
            desc: "Total number of Active participants",
            color: "#84E1BC",
          },
          {
            id: 4,
            value: data?.totalAdverseEvents,
            title: "Exclusions",
            desc: "Total number of adverse events",
            color: "#F98080",
          },
          {
            id: 5,
            value: data?.totalSites,
            title: "Sites",
            desc: "Total number of sites",
            color: "#16BDCA",
          },
        ];

        const statsObj = {
          totalQueries: data?.totalQueries,
          totalOpenQueries: data?.totalOpenQueries,
          totalClosedQueries: data?.totalClosedQueries,
          totalParticipants: data?.totalParticipants,
          totalEnrolledParticipants: data?.totalEnrolledParticipants,
          totalAdverseEvents: data?.totalAdverseEvents,
          totalSites: data?.totalSites,
          data: newData,
        };

        const newVisitData = data?.visits?.map((item: any) => ({
          completed: item?.completed,
          name: item?.name,
          total: item?.total,
          percent: item?.completionPercentage,
        }));

        setStatsData(statsObj);
        setVisitData(newVisitData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchPhaseData();
  }, [studyId]);

  return (
    <>
      {loading ? (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
          <Box
            sx={{
              bgcolor: "#FFFFFF",
              borderRadius: 2,
              p: 3,
              width: "60%",
              boxShadow: "0px 1px 3px 0px #0000001A",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10%",
                bgcolor: "#327091",
                borderRadius: 2,
                height: "190px",
                position: "relative",
                overflow: "hidden",
                mb: 2,
              }}
            >
              <Box ml={"8%"} zIndex={1}>
                <CustomCircularProgressWithLabel
                  value={31}
                  fontSize={30}
                  fontWeight={600}
                />
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: "20px", fontWeight: "700", color: "#FFFFFF" }}
                >
                  Study Progress
                </Typography>
                <Box mt={"6px"}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#FFFFFF",
                    }}
                  >
                    {statsData?.totalEnrolledParticipants}
                    <span style={{ color: "#A4CAFE" }}>
                      / {statsData?.totalParticipants} Enrolled
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ position: "absolute", right: "0px", bottom: "-6px" }}>
                <StudyProgressIcon />
              </Box>
            </Box>
            <Box
              display={"grid"}
              gridTemplateColumns={"repeat(2, 1fr)"}
              gap={2}
            >
              {visitData?.map((item) => (
                <Box key={item?.id}>
                  <StudyCard data={item} />
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            // display={"grid"}
            // gridTemplateColumns={"repeat(2, 1fr)"}
            display={"flex"}
            flexWrap={"wrap"}
            gap={2}
            width={"40%"}
          >
            {statsData?.data?.map((item: any) => (
              <Box key={item?.id} width={"45%"}>
                <StatsCards data={item} />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default DashboardContent;
