import { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import http from "../../../utils/http";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../Redux/hooks";

let schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid Email ID.")
    .required("Email is Required"),
  roles: yup
    .array()
    .of(
      yup.object().shape({
        roleId: yup.string().trim().required("Role ID is Required"),
        siteIds: yup
          .array()
          .of(yup.string().required("Site ID is Required"))
          .min(1, "At least one Site ID is required"),
      })
    )
    .min(1, "At least one role is required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
};

const AddRecordModal = ({ showModal, closeModal, refreshPage }: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const { id } = useParams();
  const { sitesList } = useAppSelector((state) => state.user);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse;
      const body = {
        ...values,
        studyId: id,
      };
      res = await http.post(`/participants`, body);

      toastMessage("success", res.data.message);
      closeModal();
      setSubmitLoader(false);
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title="Create New Participant" onCloseClick={closeModal} />
        <Formik
          initialValues={{
            email: "",
            siteId:
              sitesList?.length > 0
                ? sitesList?.find((site: any) => site.isMainSite)
                  ? sitesList?.find((site: any) => site.isMainSite === true)?.id
                  : sitesList?.[0]?.id
                : "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Participant Email <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="email"
                    {...getFieldProps("email")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                    placeholder="Enter an email address"
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Site <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    value={values?.siteId}
                    onChange={(e) => setFieldValue(`siteId`, e.target.value)}
                  >
                    {sitesList?.map((site) => (
                      <MenuItem key={site.id} value={site.id}>
                        {site.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.siteId && errors?.siteId
                      ? (errors?.siteId as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddRecordModal;
