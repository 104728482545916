import { Stack, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  QuestionSlice,
  handleFieldUpdate,
} from "../../../Redux/reducers/responseSlice";
import { useParams } from "react-router-dom";
import { updateResponse } from "../../../Redux/actions/responseAction";
import ValidationField from "../ValidationField";
import * as yup from "yup";
import { useState } from "react";

type Props = {
  currentQuestion: QuestionSlice;
};

const Text = ({ currentQuestion }: Props) => {
  const { id: studyId, surveySlug } = useParams();
  const dispatch = useAppDispatch();

  const isPreview = useAppSelector((state) => !state.response.editable);
  const isFieldSubmitting = useAppSelector(
    (state) => state.response.fieldSubmitting
  );

  const { responses, properties } = currentQuestion;
  const currentResponse = responses?.[0]?.textValue;

  const [error, setError] = useState("");

  const handleChange = (e: any) => {
    if (!isFieldSubmitting && !isPreview) {
      if (properties?.format === "email") setError("");

      dispatch(
        handleFieldUpdate({
          key: "textValue",
          convertToInt: false,
          value: e.target.value,
          id: currentQuestion.id,
        })
      );
    }
  };

  const handleBlur = (e: any) => {
    if (currentQuestion?.id && !isPreview && !isFieldSubmitting) {
      if (properties?.format === "email") {
        const isEmail = yup.string().email().isValidSync(e.target.value);

        if (isEmail) {
          setError("");
          dispatch(
            updateResponse({
              studyId,
              questionId: currentQuestion.id,
              surveySlug,
            })
          );
        } else {
          setError("Please enter a valid email");
        }
      } else {
        dispatch(
          updateResponse({
            studyId,
            questionId: currentQuestion.id,
            surveySlug,
          })
        );
      }
    }
  };

  return (
    <Stack
      sx={{
        width: {
          xs: "100%",
          md: "80%",
        },
        pl: "60px",
      }}
      gap={1}
    >
      <TextField
        name="textValue"
        fullWidth
        placeholder="Enter Value"
        value={currentResponse || ""}
        multiline={properties?.allowMultiline}
        rows={properties?.allowMultiline ? 2 : 1}
        onChange={(e) => handleChange(e)}
        onBlur={handleBlur}
        InputProps={{ readOnly: isPreview }}
        error={!!error}
        helperText={error}
      />

      {responses?.[0]?.questionValidation && (
        <ValidationField
          validationObject={responses?.[0]?.questionValidation}
        />
      )}
    </Stack>
  );
};
export default Text;
