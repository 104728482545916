import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
// import "../../utils/firebaseInit";
import StudySidebar from "./StudySidebar";
import LogoutContainer from "./LogoutContainer";
import { useEffect, useState } from "react";
import { errorToastMessage } from "../../utils/toast";
import http from "../../utils/http";
import { setRbacState } from "../../Redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";

type Props = {
  name: string;
};

const formatRbacData = (rbac: any) => {
  let studyPermissions: string[] = [];
  let sitePermissions: { [key: string]: string[] } = {};
  let studyName: string = "";
  let sitesList: any[] = [];
  Object.keys(rbac?.study).forEach((key: string) => {
    if (rbac?.study?.[key]?.permissions) {
      studyPermissions.push(...(rbac?.study?.[key]?.permissions || []));
    }
    studyName = rbac?.study[key]?.name;
  });
  sitePermissions = rbac?.site;
  let createParticipant = false;
  let readParticipant = false;
  Object.keys(rbac?.site).forEach((key: string) => {
    const permissions = rbac?.site?.[key].permissions;
    if (permissions?.includes("participant.create")) {
      createParticipant = true;
      sitesList.push({
        id: key,
        name: rbac?.site?.[key]?.name,
        isMainSite: rbac?.site?.[key]?.isMainSite,
      });
    }
    if (permissions?.includes("participant.read")) {
      readParticipant = true;
    }
  });
  if (createParticipant) {
    studyPermissions.push("participant.create");
  }
  if (readParticipant) {
    studyPermissions.push("participant.read");
  }
  return { studyPermissions, sitePermissions, studyName, sitesList };
};

const Header: React.FC<Props> = ({ name }) => {
  return (
    <Box
      sx={{
        height: "64px",
        display: "flex",
        alignItems: "center",
        p: "0px 24px",
        bgcolor: "#FFFFFF",
        borderBottom: "1px solid #E5E7EB",
      }}
    >
      <Box>
        <Typography variant="body1" color="#6B7280" fontWeight="regular">
          Study Name
        </Typography>
        <Typography variant="h6" fontWeight="medium" color="#327091">
          {name}
        </Typography>
      </Box>
      <Box sx={{ ml: "auto" }}>
        <LogoutContainer />
      </Box>
    </Box>
  );
};

const StudyLayout = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toggleLoader } = useAppSelector((state) => state.user);

  useEffect(() => {
    const fetchStudy = async () => {
      try {
        // const res = await http.get(`/study/${id}`);
        const res2 = await http.get(`/roles/self-rights?studyId=${id}`);
        const { studyPermissions, sitePermissions, studyName, sitesList } =
          formatRbacData(res2?.data?.data);
        setName(studyName);
        dispatch(
          setRbacState({ studyPermissions, sitePermissions, sitesList })
        );
        setLoading(false);
      } catch (err) {
        navigate("/app/studies");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchStudy();
  }, [setLoading, setName, id, dispatch, toggleLoader]);

  useEffect(() => {
    return () => {
      dispatch(
        setRbacState({
          studyPermissions: [],
          sitePermissions: {},
          sitesList: [],
        })
      );
    };
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <StudySidebar />
      <Box
        sx={{
          flex: 1,
          minWidth: "1px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading && (
          <Backdrop
            open={true}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Header name={name} />
        <Box
          sx={{
            flex: 1,
            minHeight: "1px",
            backgroundColor: "#FFF6EB",
          }}
        >
          {!loading && <Outlet />}
        </Box>
      </Box>
    </Box>
  );
};

export default StudyLayout;
