import { Box, Stack, Typography } from "@mui/material";
import { PageNotFoundIcon } from "../Common/assets/Icons";
import { Logo } from "../Common/assets/Sidebar";

const Error404 = () => {
  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFF6EB",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "70px",
          px: 3,
          py: 2,
          border: { xs: "1px solid #E5E7EB", md: "none" },
          backgroundColor: "#FFFFFF",
          display: "flex",
          alignItems: "center",
        }}
        gap={3}
      >
        <Logo />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <PageNotFoundIcon />
        <Typography fontWeight={600} fontSize={35} color={"#212121"}>
          Oops!
        </Typography>
        <Typography fontWeight={500} fontSize={25} color={"#212121"}>
          Page Not Found
        </Typography>
        <Typography fontWeight={400} fontSize={17} color={"#212121"}>
          Looks like the page does not exists
        </Typography>
      </Box>
    </Stack>
  );
};
export default Error404;
