import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { errorToastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import PhaseList from "../PhaseList";
import QuestionItemWrapper from "../QuestionItemWrapper";
import { useAppDispatch } from "../../../Redux/hooks";
import { getFormData } from "../../../Redux/actions/responseAction";
import { SxProps } from "@mui/material";
import { Stack } from "@mui/system";
import {
  setPhaseList,
  setResponseLoader,
} from "../../../Redux/reducers/responseSlice";
import { VisitPhaseRoot } from "../../../types/VisitPhases.types";
import { PhasesList } from "../types";
import { handlePhaseStatus } from "../utils";

const rootResponses: SxProps = {
  width: "100%",
  height: "calc(100vh - 202px)",
  display: "flex",
  gap: 1,
  p: 1,
  overflow: "auto",
  position: "relative",
  alignItems: "flex-start",
};

const VisitResponses = () => {
  const { id: studyId, participantId } = useParams();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedPhaseId, setSelectedPhaseId] = useState<string>("");

  const fetchStudyPhases = useCallback(async () => {
    try {
      if (studyId && participantId) {
        setIsLoading(true);
        let params: any = {
          participantId,
        };

        const { data } = await http.get<VisitPhaseRoot>(
          `/study/${studyId}/study-phase/participant`,
          { params }
        );

        if (data?.data.length === 0) {
          // Set Reducer Form Loading State to false
          dispatch(setResponseLoader(false));
          setIsLoading(false);
          return;
        }

        const phases: PhasesList[] = data?.data
          .map((phase) => {
            const phaseForms = phase.phaseForms
              .map((phaseForm) => ({
                position: phaseForm.position,
                id: phaseForm.form.id,
                name: phaseForm.form.name,
                attempt: phaseForm.form.formAttempts?.[0]?.id,
                status: phaseForm.form.formAttempts?.[0]?.status || "active",
                phaseName: phase.name,
              }))
              .sort((a, b) => a.position - b.position);

            const phaseStatus = handlePhaseStatus(phaseForms);

            return {
              id: phase.id,
              name: phase.name,
              position: phase.position,
              phaseStatus: phaseStatus,
              phaseForms: phaseForms,
            };
          })
          .sort((a, b) => a.position - b.position);

        let selectedForm = null;
        if (phases.length > 0) {
          const formId = sessionStorage.getItem("response-visit-form");
          let selPhase = phases?.[0].id;

          if (formId) {
            phases.forEach((phase) => {
              const selForm = phase.phaseForms.find((pf) => pf.id === formId);
              if (selForm) {
                selPhase = phase.id;
                selectedForm = selForm;
              }
            });
          }
          if (selectedForm) {
            dispatch(getFormData({ studyId, selectedForm, participantId }));
          } else {
            selectedForm = phases?.[0]?.phaseForms?.[0];
            if (selectedForm) {
              dispatch(getFormData({ studyId, selectedForm, participantId }));
            }
          }
          setSelectedPhaseId(selPhase);
        }

        dispatch(setPhaseList({ list: phases }));
        setIsLoading(false);
      }
    } catch (error) {
      dispatch(setResponseLoader(false));
      setIsLoading(false);
      errorToastMessage(error as Error);
    }
  }, [studyId, participantId, dispatch]);

  useEffect(() => {
    fetchStudyPhases();
  }, [fetchStudyPhases]);

  const handlePhaseSelect = (phaseId: string) => {
    setSelectedPhaseId((prevPhaseId) => {
      sessionStorage.setItem(
        "response-visit-phase",
        prevPhaseId === phaseId ? "" : phaseId
      );

      return prevPhaseId === phaseId ? "" : phaseId;
    });
  };

  return (
    <Stack direction={"row"} gap={2} sx={rootResponses}>
      {/* Phases and Forms */}
      <PhaseList
        isLoading={isLoading}
        selectedPhaseId={selectedPhaseId}
        handlePhaseSelect={handlePhaseSelect}
        currentTab={"Visits"}
      />

      {/* Question List */}
      <QuestionItemWrapper />
    </Stack>
  );
};
export default VisitResponses;
