import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../Common/UI/TabPanel";
import StudyTab from "./StudyTab";
import { useParams, useSearchParams } from "react-router-dom";
import { StyledHeader } from "../Common/styles/header";
import SurveyPackage from "../SurveyPackage/SurveyPackage";
import { useAppSelector } from "../../Redux/hooks";
import { permissions } from "../../utils/roles";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";

const phasesMap: any = {
  visit: { val: "visit", headerLabel: "Visit", buttonLabel: "Add Visit" },
  repeatingData: {
    val: "repeated_data",
    headerLabel: "Repeating Data",
    buttonLabel: "Add Repeating Data",
  },
  surveys: {
    val: "survey",
    headerLabel: "Surveys",
    buttonLabel: "Add Survey",
  },
  surveyPackage: {
    val: "surveyPackage",
    headerLabel: "Survey Package",
    buttonLabel: "Add Survey Package",
  },
};

const StudyDesigner = () => {
  const { id: studyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { studyPermissions } = useAppSelector((state) => state.user);
  const [canEdit] = useState(
    studyPermissions.includes(permissions.studyDesigner)
  );
  const [type, setType] = useState(searchParams.get("type") ?? "visit");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams();
    if (type) {
      params.set("type", type);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, type]);

  const handleChange = (_1: any, val: string) => {
    setType(val);
  };
  const handleExport = async () => {
    try {
      setLoading(true);
      const body = { studyId: studyId };
      let res;
      res = await http.post(`/exports`, body);
      toastMessage("success", res.data.message);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <>
      <StyledHeader>
        <Typography fontSize={20} fontWeight={600} color="#111928">
          Study Designer
        </Typography>
        {!loading ? (
          <Button variant="contained" onClick={handleExport}>
            Export Data
          </Button>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircularProgress size={25} />
          </Box>
        )}
      </StyledHeader>
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab label="Visits" value="visit" {...a11yProps(0)} />
          <StyledTab
            label="Repeating Data"
            value="repeatingData"
            {...a11yProps(1)}
          />
          <StyledTab label="Surveys" value="surveys" {...a11yProps(2)} />
          <StyledTab
            label="Survey Packages"
            value="surveyPackage"
            {...a11yProps(3)}
          />
        </StyledTabs>
      </Box>
      <Box sx={{ height: "calc(100vh - 201px)", overflow: "hidden" }}>
        <MainTabPanel value={type} index={type}>
          {phasesMap[type]?.val !== "surveyPackage" ? (
            <StudyTab type={phasesMap[type]} canEdit={canEdit} />
          ) : (
            <SurveyPackage type={phasesMap[type]} canEdit={canEdit} />
          )}
        </MainTabPanel>
      </Box>
    </>
  );
};

export default StudyDesigner;
