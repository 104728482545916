import { Add, Delete } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  handleAddRemoveChoices,
  handleChoiceModification,
} from "../../../Redux/reducers/questionSlice";

type Props = {
  question: any;
};

const ChoiceTab = ({ question }: Props) => {
  const dispatch = useAppDispatch();
  const { choices }: { choices: any[] } = question || [];
  const isChoicesError = useAppSelector(
    ({ question }) => question.isChoicesError
  );
  const isPreview = useAppSelector(({ question }) => !question.editable);

  return (
    <Stack gap={1} py={1}>
      {isChoicesError && (
        <Typography variant="caption" fontWeight="medium" color={"red"}>
          {isChoicesError}
        </Typography>
      )}

      <Stack direction="row" alignItems={"center"} mb={3} gap={2}>
        <Typography sx={{ fontWeight: 600, fontSize: 14, width: "32%" }}>
          Label
        </Typography>
        <Typography sx={{ fontWeight: 600, fontSize: 14, width: "32%" }}>
          Value
        </Typography>
        <Typography sx={{ fontWeight: 600, fontSize: 14 }}>Is Other</Typography>
        <Typography sx={{ fontWeight: 600, fontSize: 14 }}>Actions</Typography>
      </Stack>

      {choices &&
        choices.map((choice, i) => (
          <Stack
            key={choice.ref}
            direction="row"
            alignItems={"center"}
            mb={3}
            gap={2}
          >
            <TextField
              sx={{ width: "32%" }}
              placeholder=" Label"
              value={choice.label}
              onChange={(e) =>
                dispatch(
                  handleChoiceModification({
                    currentRef: choice.ref,
                    section: "label",
                    value: e.target.value,
                  })
                )
              }
              name="option-label"
              InputProps={{
                readOnly: isPreview,
              }}
            />

            <TextField
              sx={{ width: "32%" }}
              type="number"
              placeholder="Value"
              value={choice.value}
              onChange={(e) =>
                dispatch(
                  handleChoiceModification({
                    currentRef: choice.ref,
                    section: "value",
                    value: e.target.value,
                  })
                )
              }
              name="option-value"
              InputProps={{
                readOnly: isPreview,
              }}
            />

            {question.type !== "dropdown" && (
              <Switch
                checked={choice.isOther}
                onChange={(e) =>
                  dispatch(
                    handleChoiceModification({
                      currentRef: choice.ref,
                      section: "isOther",
                      value: e.target.checked,
                    })
                  )
                }
                disabled={isPreview}
              />
            )}
            {!isPreview && (
              <Stack direction={"row"} alignItems={"center"} gap={3}>
                <IconButton
                  aria-label="add"
                  size="medium"
                  onClick={() =>
                    dispatch(
                      handleAddRemoveChoices({
                        actionType: "add",
                        actionIndex: i,
                      })
                    )
                  }
                >
                  <Add fontSize="medium" sx={{ color: "primary.main" }} />
                </IconButton>

                {i !== 0 && (
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    color="error"
                    onClick={() =>
                      dispatch(
                        handleAddRemoveChoices({
                          actionType: "remove",
                          actionIndex: i,
                          removedRef: choice.ref,
                        })
                      )
                    }
                  >
                    <Delete fontSize="medium" sx={{ color: "error.main" }} />
                  </IconButton>
                )}
              </Stack>
            )}
          </Stack>
        ))}
    </Stack>
  );
};
export default ChoiceTab;
