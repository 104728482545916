import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DropArea from "./DropArea/DropArea";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import QBHeader from "./QBHeader";
import { loadQuestionDetails } from "../../Redux/actions/questionAction";
import { styled } from "@mui/material/styles";
import QuestionPicker from "./QuestionPicker/QuestionPicker";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { resetQuestionState } from "../../Redux/reducers/questionSlice";
import QuestionPropertiesModalWrapper from "./QuestionProperties/QuestionPropertiesModalWrapper";

const QBArea = styled("div")({
  height: "calc(100vh - 144px)",
  display: "flex",
  alignItems: "start",
  padding: "8px",
});

const QuestionBuilder = () => {
  const { id: studyId, formId } = useParams();
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.question.loading);

  useEffect(() => {
    if (studyId && formId) dispatch(loadQuestionDetails(studyId, formId));
  }, [dispatch, studyId, formId]);

  useEffect(() => {
    return () => {
      dispatch(resetQuestionState());
    };
  }, [dispatch]);

  return (
    <Box>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <QBHeader />
      <QBArea>
        <QuestionPicker />
        <DropArea />
        <QuestionPropertiesModalWrapper />
      </QBArea>
    </Box>
  );
};

export default QuestionBuilder;
