import { Stack, TextField, Box, SxProps, Typography } from "@mui/material";

type Props = {
  currentQuestion: any;
};

const radioWrapperStyle: SxProps = {
  width: "350px",
  minHeight: "55px",
  borderRadius: 1,

  border: "1px solid",
  borderColor: "divider",

  "&:hover": {
    cursor: "pointer",
    bgcolor: "badge.bgActive",
    borderColor: "primary.main",

    "&>div": {
      borderColor: "primary.main",
      color: "primary.main",
    },
  },
};

const radioIndex: SxProps = {
  p: 2,

  borderRight: "1px solid",
  borderColor: "divider",
  width: "48px",
  height: "100%",
};

const radioValue: SxProps = {
  p: 2,
  flex: 1,
  alignItems: "center",
  justifyContent: "space-between",
};

const RadioFieldPreview = ({ currentQuestion }: Props) => {
  const { choices, properties } = currentQuestion;

  return (
    <Stack
      gap={2}
      direction={properties?.orientation === "vertical" ? "column" : "row"}
      flexWrap={"wrap"}
      alignItems={
        properties?.orientation === "vertical" ? "flex-start" : "center"
      }
    >
      {choices?.map((choice: any, index: number) => (
        <Stack
          key={choice?.id}
          direction={"row"}
          gap={1}
          alignItems={"center"}
          sx={radioWrapperStyle}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Box sx={radioIndex}>{String.fromCharCode(65 + index)}</Box>

            <Stack
              sx={radioValue}
              direction={choice?.isOther ? "column-reverse" : "row"}
              gap={1}
            >
              <Typography
                fontWeight={500}
                // sx={{ width: choice?.isOther ? 70 : 265 }}
                // noWrap
                title={choice?.label}
              >
                {choice?.label}
              </Typography>
              {choice?.isOther && (
                <TextField
                  fullWidth
                  name="textValue"
                  placeholder="Enter Value"
                  sx={{ bgcolor: "white" }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
export default RadioFieldPreview;
