import {
  useState,
  // useState,
} from "react";
import { Box, Button, Typography } from "@mui/material";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import { Add } from "@mui/icons-material";
import AddRecordModal from "./Modals/AddRecordModal";

type Props = {
  refreshPage: () => void;
  addUser: boolean;
};

const RecordsHeader = ({ refreshPage, addUser }: Props) => {
  // const [loader] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={20} fontWeight={600} color="#111928">
          Participants
        </Typography>
      </Box>

      {addUser && (
        <Box sx={HeaderRightContent}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setShowModal(true)}
          >
            New
          </Button>
        </Box>
      )}
      {showModal && (
        <AddRecordModal
          showModal={showModal}
          closeModal={closeModal}
          refreshPage={refreshPage}
        />
      )}
    </StyledHeader>
  );
};

export default RecordsHeader;
