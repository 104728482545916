import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  SxProps,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import httpSurvey from "../../utils/httpSurvey";
import { errorToastMessage } from "../../utils/toast";
import { SurveyBySlugRoot } from "../../types/SurveyBySlug.types";
import { PhasesList } from "../Responses/types";
import { getFormData } from "../../Redux/actions/responseAction";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { setPhaseList } from "../../Redux/reducers/responseSlice";
import PhaseList from "../Responses/PhaseList";
import QuestionItemWrapper from "../Responses/QuestionItemWrapper";
import { Logo } from "../Common/assets/Sidebar";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import MobileDrawer from "./MobileDrawer";
import { handlePhaseStatus } from "../Responses/utils";
import ConfirmationModalWrapper from "../Responses/ConfirmationModalWrapper";

const rootResponses: SxProps = {
  height: "calc(100vh - 70px)",
  width: "100%",
  p: { xs: 0, md: 2 },
  backgroundColor: "#FFF6EB",
  overflow: "auto",
  position: "relative",
  alignItems: "flex-start",
};

const Survey = () => {
  const { surveySlug } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isFieldSubmitting = useAppSelector(
    (state) => state.response.fieldSubmitting
  );

  const [isLoading, setIsLoading] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [packageName, setPackageName] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);

  const fetchStudyPhases = useCallback(async () => {
    try {
      setIsLoading(true);

      if (surveySlug) {
        const { data } = await httpSurvey.get<SurveyBySlugRoot>(
          `/survey/${surveySlug}`
        );

        setPackageName(data?.data.package.name);

        let phases: PhasesList[] = [];

        data?.data.package.surveyLinks.forEach((link) => {
          if (link.phase) {
            const phaseForms = link.phase.phaseForms
              .map((phaseForm) => ({
                position: phaseForm.position,
                id: phaseForm.form.id,
                name: phaseForm.form.name,
                attempt: phaseForm.form.formAttempts?.[0]?.id,
                status: phaseForm.form.formAttempts?.[0]?.status || "active",
                phaseName: link.phase.name,
              }))
              .sort((a, b) => a.position - b.position);

            const phaseStatus = handlePhaseStatus(phaseForms);

            phases.push({
              id: link.id,
              name: link.phase.name,
              position: link.position,
              phaseForms,
              phaseStatus,
            });
          }
        });

        phases.sort((a, b) => a.position - b.position);

        let selectedForm = null;
        if (phases.length > 0) {
          const formId = sessionStorage.getItem("response-visit-form");
          let selPhase = phases?.[0].id;

          if (formId) {
            phases.forEach((phase) => {
              const selForm = phase.phaseForms.find((pf) => pf.id === formId);
              if (selForm) {
                selPhase = phase.id;
                selectedForm = selForm;
              }
            });
          }
          if (selectedForm) {
            dispatch(getFormData({ surveySlug, selectedForm }));
          } else {
            selectedForm = phases?.[0]?.phaseForms?.[0];
            if (selectedForm) {
              dispatch(getFormData({ surveySlug, selectedForm }));
            }
          }
          setSelectedSurveyId(selPhase);
        }

        dispatch(setPhaseList({ list: phases }));
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      errorToastMessage(error);

      if (error?.response.data?.metadata?.redirect === "error") {
        navigate("/404");
      }
    }
  }, [dispatch, surveySlug]);

  useEffect(() => {
    fetchStudyPhases();
  }, [fetchStudyPhases]);

  const handlePhaseSelect = (phaseId: string) => {
    setSelectedSurveyId((prevPhaseId) => {
      sessionStorage.setItem(
        "response-visit-phase",
        prevPhaseId === phaseId ? "" : phaseId
      );

      return prevPhaseId === phaseId ? "" : phaseId;
    });
  };

  return (
    <Stack sx={{ height: "100vh" }}>
      {/* Navbar */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{
          height: "70px",
          px: 3,
          py: 2,
          border: { xs: "1px solid #E5E7EB", md: "none" },
        }}
        gap={3}
      >
        <Logo />
        <IconButton
          aria-label="menu"
          size="large"
          sx={{ display: { xs: "block", md: "none" }, ml: "auto" }}
          onClick={() => setShowDrawer(true)}
        >
          <MenuIcon fontSize="inherit" />
        </IconButton>

        {isFieldSubmitting && (
          <CircularProgress sx={{ ml: "auto" }} color="primary" size={24} />
        )}
      </Stack>

      {/* Body */}
      <Stack direction={"row"} gap={2} sx={rootResponses}>
        {/* Phases and Forms */}
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <PhaseList
            packageName={packageName}
            isLoading={isLoading}
            selectedPhaseId={selectedSurveyId}
            handlePhaseSelect={handlePhaseSelect}
            currentTab={"Surveys"}
          />
        </Box>

        {/* Question List */}
        <QuestionItemWrapper />

        {showDrawer && (
          <MobileDrawer
            showDrawer={showDrawer}
            onClose={() => setShowDrawer(false)}
          >
            <PhaseList
              packageName={packageName}
              isLoading={isLoading}
              selectedPhaseId={selectedSurveyId}
              handlePhaseSelect={handlePhaseSelect}
              closeMobileDrawer={() => setShowDrawer(false)}
              currentTab={"Surveys"}
            />
          </MobileDrawer>
        )}
      </Stack>

      <ConfirmationModalWrapper />
    </Stack>
  );
};

export default Survey;
