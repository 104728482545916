import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    neutral: Palette["primary"];
    neutralLight: Palette["primary"];
    badge?: {
      greenText: string;
      greenBg: string;
      redText: string;
      redBg: string;
      orangeText: string;
      orangeBg: string;

      bgActive: string;
    };
  }

  interface Palette {
    neutral?: Palette["primary"];
    neutralLight?: Palette["primary"];
    badge?: {
      greenText: string;
      greenBg: string;
      redText: string;
      redBg: string;
      orangeText: string;
      orangeBg: string;

      bgActive: string;
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    neutralLight: true;
  }
}

export const theme = createTheme({
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    body1: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "150%",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "150%",
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "150%",
    },
    h2: {
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "150%",
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "150%",
    },
  },
  palette: {
    primary: {
      main: "#327091",
      light: "#37afa5",
    },
    neutral: {
      main: "#818194",
      contrastText: "#FFFFFF",
      dark: "#6a6a7a",
      light: "#9494a8",
    },
    badge: {
      greenBg: "rgba(51, 188, 40, 0.1)",
      greenText: "#33BC28",
      redText: "#E02424",
      redBg: "#ff6854",
      orangeBg: "#FEECDC",
      orangeText: "#FF8A4C",

      bgActive: "#E3F1F4",
    },
    neutralLight: {
      main: "#f5f5f5",
      contrastText: "#1F2A37",
      dark: "#e0dede",
      light: "#f5f5f5",
    },
    text: {
      primary: "#111928",
    },
    divider: "#e0e3eb",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fieldSet: {
            borderColor: "#e0e3eb",
          },
          "& input::placeholder": {
            color: "#637e85",
            fontWeight: 400,
          },
          "& .MuiSvgIcon-root": {
            color: "#637e85",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontSize: "16px",
          height: "48px",
          padding: "15px 20px",
          fontWeight: 600,
        },
      },
    },
  },
});
