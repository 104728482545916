export const ImageUploadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.0001 10V22V10Z" fill="#327091" />
    <path
      d="M7.00007 16C6.01509 16.0011 5.06433 15.6388 4.32994 14.9823C3.59556 14.3259 3.12919 13.4216 3.02018 12.4427C2.91118 11.4638 3.1672 10.479 3.73921 9.67714C4.31121 8.87526 5.15897 8.31263 6.12007 8.09703C5.84199 6.80012 6.0905 5.44586 6.81092 4.33218C7.53134 3.21849 8.66467 2.43661 9.96157 2.15853C11.2585 1.88045 12.6127 2.12895 13.7264 2.84937C14.8401 3.56979 15.622 4.70312 15.9001 6.00003H16.0001C17.24 5.99878 18.4362 6.45831 19.3564 7.2894C20.2766 8.12049 20.8552 9.26386 20.9798 10.4975C21.1044 11.7312 20.7662 12.9672 20.0308 13.9655C19.2954 14.9638 18.2152 15.6533 17.0001 15.9M15.0001 13L12.0001 10M12.0001 10L9.00007 13M12.0001 10V22"
      stroke="#327091"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const CopyContentIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.8999 10.8004C8.8999 10.2965 9.10008 9.81321 9.4564 9.45689C9.81272 9.10057 10.296 8.90039 10.7999 8.90039H17.9999C18.5038 8.90039 18.9871 9.10057 19.3434 9.45689C19.6997 9.81321 19.8999 10.2965 19.8999 10.8004V18.0004C19.8999 18.5043 19.6997 18.9876 19.3434 19.3439C18.9871 19.7002 18.5038 19.9004 17.9999 19.9004H10.7999C10.296 19.9004 9.81272 19.7002 9.4564 19.3439C9.10008 18.9876 8.8999 18.5043 8.8999 18.0004V10.8004Z"
      fill="#637E85"
      stroke="#637E85"
    />
    <path
      d="M6.0001 3.59961C5.36358 3.59961 4.75313 3.85247 4.30304 4.30255C3.85295 4.75264 3.6001 5.36309 3.6001 5.99961V13.1996C3.6001 13.8361 3.85295 14.4466 4.30304 14.8967C4.75313 15.3468 5.36358 15.5996 6.0001 15.5996V5.99961H15.6001C15.6001 5.36309 15.3472 4.75264 14.8972 4.30255C14.4471 3.85247 13.8366 3.59961 13.2001 3.59961H6.0001Z"
      fill="#355962"
    />
  </svg>
);

export const UploadErrorIcon = () => (
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.5 18.6667V31.5M31.5 44.3333H31.5321M60.375 31.5C60.375 35.2919 59.6281 39.0467 58.177 42.55C56.7259 46.0533 54.599 49.2364 51.9177 51.9177C49.2364 54.599 46.0533 56.7259 42.55 58.177C39.0467 59.6281 35.2919 60.375 31.5 60.375C27.7081 60.375 23.9533 59.6281 20.45 58.177C16.9467 56.7259 13.7636 54.599 11.0823 51.9177C8.401 49.2364 6.27408 46.0533 4.82298 42.55C3.37187 39.0467 2.625 35.2919 2.625 31.5C2.625 23.8419 5.66718 16.4974 11.0823 11.0823C16.4974 5.66718 23.8419 2.625 31.5 2.625C39.1581 2.625 46.5026 5.66718 51.9177 11.0823C57.3328 16.4974 60.375 23.8419 60.375 31.5Z"
      stroke="#F05252"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
