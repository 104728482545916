import React from "react";
import { useAppSelector } from "../../../Redux/hooks";
import { Box, SxProps } from "@mui/material";
import QuestionItemWrapper from "./QuestionItemWrapper";

export const QuestionListStyle: SxProps = {
  flex: "1",
  height: "100%",
  px: 1,
  overflowY: "auto",
};

const DropArea: React.FC = () => {
  const questions = useAppSelector((state) => state.question.questions);

  return (
    <Box sx={QuestionListStyle}>
      {questions.map((question, index) => (
        <QuestionItemWrapper
          key={question.id}
          index={index}
          question={question}
        />
      ))}
    </Box>
  );
};

export default DropArea;
