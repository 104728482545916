import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridPagination,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { useNavigate, useParams } from "react-router-dom";
import {
  NoDataContainer,
  StyledDataGrid,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
} from "../Common/styles/table";
import { LockedIcon, UnlockedIcon } from "../Common/assets/Icons";
import ParticipantArchiveModal from "./Modals/ParticipantArchiveModal";
import { useAppSelector } from "../../Redux/hooks";
import { permissions } from "../../utils/roles";

function CustomPagination(props: any) {
  return (
    <GridPagination
      sx={TablePaginationStyle}
      labelDisplayedRows={paginationLabel}
      {...props}
    />
  );
}

type Props = {
  toggleLoader: boolean;
  type: string;
  page: number;
  setPage: (page: number) => void;
  paginationModel: any;
  setPaginationModel: (model: any) => void;
  refreshPage: () => void;
};

const permissionAccess = (
  siteId: string,
  permission: string,
  sitePermissions: any
) => {
  if (sitePermissions?.[siteId]?.permissions?.includes(permission)) {
    return true;
  }
  return false;
};
const RecordsList = ({
  toggleLoader,
  type,
  page,
  setPage,
  paginationModel,
  setPaginationModel,
  refreshPage,
}: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [participants, setParticipants] = useState<any[]>([]);
  const [totalParticipants, setTotalParticipants] = useState(1);
  const { id } = useParams();
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const { sitePermissions } = useAppSelector((state) => state.user);
  const [selectedRecord, setSelectedRecord] = useState<string>("");
  const [lockLoader, setLockLoader] = useState(false);
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        setLoading(true);
        let url: string = `/participants?studyId=${id}&page=${
          page + 1
        }&size=${pageSize}`;
        const res: AxiosResponse = await http.get(url);
        const data = res?.data?.data?.data;
        const newData = data?.map((item: any) => ({
          id: item?.id,
          participantId: item?.formattedSubjectId,
          site: item?.site?.name,
          randomization: item?.randomization || "-",
          progress: item?.progress || "-",
          createdOn: item?.createdOn || "-",
          status: item?.status || "-",
          isLocked: item?.isLocked || false,
          isArchived: item?.isArchived || false,
          canLock: permissionAccess(
            item?.site?.id,
            permissions.recordLockUnlock,
            sitePermissions
          ),
          canArchive: permissionAccess(
            item?.site?.id,
            permissions.recordArchive,
            sitePermissions
          ),
        }));
        setParticipants(newData);
        setTotalParticipants(res?.data?.data?.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchUsersData();
  }, [type, toggleLoader, page, id, sitePermissions]);

  const handleChangePage = (page: any) => {
    setPaginationModel(page);
    setPage(page.page);
  };

  // const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event?.currentTarget);
  // };

  // const handleFilterItem = (id: string) => {
  //   setSelectedOption(id);
  // };

  // const handleClickOK = () => {
  //   setAnchorEl(null);
  //   if (selectedOption !== filterId) {
  //     dispatch(setParticipantsFilterId(selectedOption));
  //   }
  // };

  // const handleActionClick = useCallback(async (params: GridCellParams, type: string) => {
  //   if()
  // }, []);
  const handleActionClick = useCallback(
    async (params: GridCellParams, action: string) => {
      try {
        let url: string = ``;
        setLockLoader(true);
        if (action === "lockUnlock") {
          url = `/participants/${params?.row?.id}/${
            params?.row?.isLocked ? "unlock" : "lock"
          }?studyId=${id}`;
        } else if (action === "unarchive") {
          url = `participants/${params?.row?.id}/unarchive`;
        }
        let res: AxiosResponse = await http.post(
          url,
          action === "unarchive" ? { studyId: id } : undefined
        );
        setLockLoader(false);
        toastMessage("success", res?.data?.message);
        refreshPage();
      } catch (err) {
        setLockLoader(false);
        errorToastMessage(err as Error);
      }
    },
    [id, refreshPage]
  );

  const archiveClick = (params: GridCellParams) => {
    setSelectedRecord(params?.row?.id);
    setShowArchive(true);
  };
  const closeArchiveModal = () => {
    setShowArchive(false);
  };

  const columns = useMemo<GridColDef<any>[]>(
    () =>
      participants
        ? [
            {
              field: "participantId",
              headerName: "Participant ID",
              flex: 1,
              sortable: false,
              renderCell: (params: GridRenderCellParams<any>) => {
                return (
                  <Box
                    sx={{
                      cursor: params?.row?.isArchived ? "default" : "pointer",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={500}>
                      {params?.row?.participantId}
                    </Typography>
                  </Box>
                );
              },
            },
            {
              field: "site",
              headerName: "Site",
              flex: 1,
              sortable: false,
            },
            {
              field: "randomization",
              headerName: "Randomization",
              flex: 1,
              sortable: false,
            },
            {
              field: "progress",
              headerName: "Progress",
              flex: 1,
              sortable: false,
            },
            {
              field: "createdOn",
              headerName: "Created On",
              flex: 1,
              sortable: false,
            },
            {
              field: "status",
              headerName: "Status",
              flex: 1,
              sortable: false,
            },
            {
              field: "lock",
              headerName: "Lock",
              flex: 1,
              sortable: false,
              renderHeader: () => (
                <Box display={"flex"} ml={0.5}>
                  <LockedIcon />
                </Box>
              ),
              renderCell: (params: GridRenderCellParams<any>) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => handleActionClick(params, "lockUnlock")}
                      disabled={
                        lockLoader ||
                        params?.row?.isArchived ||
                        !params?.row?.canLock
                      }
                    >
                      {params?.row?.isLocked ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LockedIcon />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <UnlockedIcon />
                        </Box>
                      )}
                    </IconButton>
                  </Box>
                );
              },
            },
            {
              field: "id",
              type: "actions",
              headerAlign: "right",
              align: "right",
              getActions: (params: any) => [
                <GridActionsCellItem
                  showInMenu
                  key="archive"
                  label={params?.row?.isArchived ? "Un-archive" : "Archive"}
                  onClick={() =>
                    params?.row?.isArchived
                      ? handleActionClick(params, "unarchive")
                      : archiveClick(params)
                  }
                  disabled={lockLoader || !params?.row?.canArchive}
                  sx={{
                    "& .MuiListItemIcon-root": {
                      minWidth: 0,
                    },
                  }}
                />,
                <GridActionsCellItem
                  showInMenu
                  key="lock"
                  label={params?.row?.isLocked ? "Unlock" : "Lock"}
                  onClick={() => handleActionClick(params, "lockUnlock")}
                  disabled={
                    params?.row?.isArchived ||
                    !params?.row?.canLock ||
                    lockLoader
                  }
                  sx={{
                    "& .MuiListItemIcon-root": {
                      minWidth: 0,
                    },
                  }}
                />,
              ],
            },
          ]
        : [],
    [participants, handleActionClick, lockLoader]
  );

  const handleCellClick = (params: GridCellParams) => {
    const { field, row } = params;
    if (field === "participantId" && !params?.row?.isArchived) {
      navigate(
        `/studies/${id}/responses/${row?.id}?participant=${row.participantId}`
      );
    }
  };

  return (
    <>
      <StyledDataGrid
        rows={loading ? [] : participants}
        loading={loading}
        slots={{
          loadingOverlay: () => <LinearProgress />,
          pagination: CustomPagination,
          noRowsOverlay: () => (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          ),
        }}
        onCellClick={handleCellClick}
        hideFooter={totalParticipants < pageSize}
        columns={columns}
        keepNonExistentRowsSelected
        disableRowSelectionOnClick
        // checkboxSelection
        // onRowSelectionModelChange={(newRowSelectionModel: any) => {
        //   dispatch(setSelectedRows(newRowSelectionModel));
        // }}
        // rowSelectionModel={selectedRows}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(newPage) => handleChangePage(newPage)}
        pageSizeOptions={[pageSize]}
        rowCount={totalParticipants}
        disableColumnMenu
        autoHeight
        columnHeaderHeight={52}
        rowHeight={65}
      />
      {showArchive && (
        <ParticipantArchiveModal
          openModal={showArchive}
          closeModal={closeArchiveModal}
          refresh={refreshPage}
          participantId={selectedRecord}
        />
      )}
    </>
  );
};

export default RecordsList;
