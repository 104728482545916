import { Drawer, IconButton, Stack } from "@mui/material";
import { Logo } from "../Common/assets/Sidebar";
import CloseIcon from "@mui/icons-material/Close";

const MobileDrawer = ({
  showDrawer,
  onClose,
  children,
}: {
  showDrawer: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Drawer open={showDrawer} onClose={onClose}>
      <Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ height: "70px", p: 2 }}
          gap={3}
        >
          <Logo />

          <IconButton aria-label="menu" size="large" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>

        {children}
      </Stack>
    </Drawer>
  );
};
export default MobileDrawer;
