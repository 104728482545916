import { Avatar, Box, Typography } from "@mui/material";
import { Logo } from "../Common/assets/Sidebar";
import { BellIcon } from "../Common/assets/Icons";
import { HeaderStyle } from "../Common/styles/header";
import DashboardContent from "./DashboardContent/DashboardContent";

const Dashboard = () => {
  return (
    <>
      {/* <Box
        sx={{
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "0px 24px",
          bgcolor: "#FFFFFF",
          borderBottom: "1px solid #E5E7EB",
        }}
      >
        <Logo />
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <BellIcon />
          <Avatar
            sx={{
              width: 40,
              height: 40,
              bgcolor: "lightgray",
              color: "#000",
              fontSize: 16,
            }}
          />
        </Box>
      </Box> */}
      <Box sx={HeaderStyle}>
        <Typography fontSize={30} fontWeight="700" color="text.primary">
          Dashboard
        </Typography>
      </Box>
      <Box sx={{ p: 3, height: "calc(100vh - 140px)", overflow: "scroll" }}>
        <DashboardContent />
      </Box>
    </>
  );
};

export default Dashboard;
