import { Box, Stack, SxProps, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { useParams } from "react-router-dom";
import { updateResponse } from "../../../Redux/actions/responseAction";
import {
  QuestionSlice,
  handleCheckboxIsOtherUpdate,
  handleCheckboxUpdate,
} from "../../../Redux/reducers/responseSlice";
import ValidationField from "../ValidationField";

type Props = {
  currentQuestion: QuestionSlice;
};

const radioWrapperStyle: SxProps = {
  width: "100%",
  minHeight: "55px",
  borderRadius: 1,

  border: "1px solid",
  borderColor: "divider",

  "&:hover": {
    cursor: "pointer",
    bgcolor: "badge.bgActive",
    borderColor: "primary.main",

    "&>div": {
      borderColor: "primary.main",
      color: "primary.main",
    },
  },
};

const radioIndex: SxProps = {
  p: 2,

  borderRight: "1px solid",
  borderColor: "divider",
  width: "48px",
  height: "100%",
};

const radioValue: SxProps = {
  p: 2,
  flex: 1,
  alignItems: "center",
  justifyContent: "space-between",
};

const radioWrapperActiveStyle: SxProps = {
  width: "100%",
  minHeight: "55px",
  borderRadius: 1,
  border: "1px solid",
  bgcolor: "badge.bgActive",
  borderColor: "primary.main",
  "&:hover": {
    cursor: "pointer",
  },
};

const CheckboxField = ({ currentQuestion }: Props) => {
  const { id: studyId, surveySlug } = useParams();
  const dispatch = useAppDispatch();

  const isPreview = useAppSelector((state) => !state.response.editable);
  const isFieldSubmitting = useAppSelector(
    (state) => state.response.fieldSubmitting
  );

  const { responses, choices, properties } = currentQuestion;

  const handleChange = (choiceId: string, textValue?: string) => {
    if (!isFieldSubmitting && !isPreview) {
      dispatch(
        handleCheckboxUpdate({
          choiceId,
          textValue,
          id: currentQuestion.id,
        })
      );

      if (currentQuestion?.id && typeof textValue === "undefined") {
        dispatch(
          updateResponse({
            studyId,
            questionId: currentQuestion.id,
            surveySlug,
          })
        );
      }
    }
  };

  const handleIsOtherChange = (choiceId: string, textValue: string) => {
    if (!isFieldSubmitting && !isPreview) {
      dispatch(
        handleCheckboxIsOtherUpdate({
          choiceId,
          textValue,
          id: currentQuestion.id,
        })
      );
    }
  };

  const handleBlur = () => {
    if (currentQuestion?.id && !isPreview && !isFieldSubmitting) {
      dispatch(
        updateResponse({
          studyId,
          questionId: currentQuestion.id,
          surveySlug,
        })
      );
    }
  };

  return (
    <Stack
      sx={{
        width: "100%",
        pl: {
          xs: 0,
          md: "60px",
        },
      }}
      gap={1}
    >
      <Stack
        gap={2}
        direction={properties?.orientation === "vertical" ? "column" : "row"}
        flexWrap={"wrap"}
        alignItems={
          properties?.orientation === "vertical" ? "flex-start" : "center"
        }
      >
        {choices?.map((choice, index) => (
          <Stack
            key={choice?.id}
            direction={"row"}
            gap={1}
            alignItems={"center"}
            sx={{
              width: {
                xs: "100%",
                md: "350px",
              },
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={
                responses &&
                responses?.find((r) => r.questionChoiceId === choice?.id)
                  ? radioWrapperActiveStyle
                  : radioWrapperStyle
              }
              onClick={() => {
                if (!isPreview && !choice?.isOther) {
                  handleChange(choice?.id);
                }
              }}
            >
              <Box sx={radioIndex}>{String.fromCharCode(65 + index)}</Box>
              <Stack
                sx={radioValue}
                direction={choice?.isOther ? "column-reverse" : "row"}
                gap={1}
              >
                <Typography
                  fontWeight={500}
                  // sx={{ width: choice?.isOther ? 70 : 265 }}
                  // noWrap
                  title={choice?.label}
                >
                  {choice?.label}
                </Typography>
                {choice?.isOther && (
                  <TextField
                    fullWidth
                    name="textValue"
                    placeholder="Enter Value"
                    sx={{ bgcolor: "white" }}
                    value={
                      responses?.find((r) => r.questionChoiceId === choice?.id)
                        ?.textValue || ""
                    }
                    onChange={(e) => {
                      handleIsOtherChange(choice?.id, e.target.value);
                    }}
                    onBlur={handleBlur}
                    InputProps={{ readOnly: isPreview }}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
      {responses?.[0]?.questionValidation && (
        <ValidationField
          validationObject={responses?.[0]?.questionValidation}
        />
      )}
    </Stack>
  );
};
export default CheckboxField;
