import { Stack, TextField } from "@mui/material";

type Props = {
  currentQuestion: any;
};

const NumberFieldPreview = ({ currentQuestion }: Props) => {
  return (
    <Stack gap={1}>
      <TextField
        name="textValue"
        sx={{
          width:
            typeof currentQuestion?.properties?.fieldWidth === "number"
              ? 32 + (currentQuestion?.properties?.fieldWidth - 4) * 3 + "%"
              : "80%",
        }}
        placeholder=""
        type="number"
      />
    </Stack>
  );
};
export default NumberFieldPreview;
