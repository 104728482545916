import { memo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconMap } from "../questionTypes";
import { deleteQuestion } from "../../../Redux/actions/questionAction";
import {
  setQuestionModalDetails,
  setQuestionSubmitting,
} from "../../../Redux/reducers/questionSlice";
import { copyQuestionMiddleware } from "../QuestionProperties/utils";
import DeleteConfirmModal from "./DeleteConfirm";
import { errorToastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import RadioFieldPreview from "../PreviewItems/RadioFieldPreview";
import DropdownPreview from "../PreviewItems/DropdownPreview";
import GridFieldPreview from "../PreviewItems/GridFieldPreview";
import CalculatedFieldPreview from "../PreviewItems/CalculatedFieldPreview";
import TextFieldPreview from "../PreviewItems/TextFieldPreview";
import NumberFieldPreview from "../PreviewItems/NumberFieldPreview";
import SliderPreview from "../PreviewItems/SliderPreview";
import DatePreview from "../PreviewItems/DatePreview";
import UploadFilePreview from "../PreviewItems/UploadFile";
import RepeatedFieldPreview from "../PreviewItems/RepeatedFieldPreview";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
} from "../../Common/assets/Icons";

type Props = {
  question: any;
  index: number;
};

export const QuestionItemStyle: SxProps = {
  width: "100%",

  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",

  py: 2,
  px: 2,
  border: 1,

  borderColor: "#E7E7E7",
  borderRadius: "12px",
  backgroundColor: "#FFFFFF",

  "&:not(:last-child)": {
    marginBottom: 2.5,
  },
};
export const QuestionDescriptorStyle: SxProps = {
  background: "#ffffff",

  border: 1,
  borderColor: "#CFCFC9",
  borderRadius: 1,

  width: "76px",
  height: "40px",

  mr: 2,
  px: 1.5,
};

const componentMap: Record<string, any> = {
  radio: RadioFieldPreview,
  dropdown: DropdownPreview,
  checkbox: RadioFieldPreview,
  grid: GridFieldPreview,
  calculated_field: CalculatedFieldPreview,
  text: TextFieldPreview,
  number: NumberFieldPreview,
  slider: SliderPreview,
  date: DatePreview,
  upload_file: UploadFilePreview,
  repeated_data: RepeatedFieldPreview,
  statement: null,
};

const requiredStyles: SxProps = {
  // maxWidth: 670,
  "&::after": {
    content: '"*"',
    color: "red",
    marginLeft: "3px",
  },
};

const QuestionItemWrapper: React.FC<Props> = ({ question, index }) => {
  const dispatch = useAppDispatch();

  const {
    qid: formId,
    studyId,
    editable,
    formSubmitting,
  } = useAppSelector((state) => state.question);

  const Icon = IconMap[question.type];

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChoices, setShowChoices] = useState(false);

  const handleCopyQuestion = () => {
    const modifiedQuestion = copyQuestionMiddleware(question);
    dispatch(
      setQuestionModalDetails({
        question: modifiedQuestion,
        show: true,
        modalIsEdit: false,
      })
    );
  };

  const handleEditQuestion = () => {
    dispatch(
      setQuestionModalDetails({
        question,
        show: true,
        modalIsEdit: true,
      })
    );
  };

  const handleDeleteQuestion = () => {
    dispatch(deleteQuestion(question.id));
  };

  const handleDeleteQuestionPopup = async () => {
    if (!formSubmitting) {
      dispatch(setQuestionSubmitting(true));

      try {
        const { data } = await http.get(
          `/study/${studyId}/forms/${formId}/question/${question.id}/dependencies`
        );

        if (data.data?.length === 0) {
          handleDeleteQuestion();
        } else {
          dispatch(setQuestionSubmitting(false));
          setShowDeleteModal(true);
        }
      } catch (error) {
        dispatch(setQuestionSubmitting(false));
        errorToastMessage(error as Error);
      }
    }
  };

  const Component = componentMap[question.type];

  return (
    <Stack sx={QuestionItemStyle} justifyContent={"center"} gap={2}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction="row" alignItems={"center"}>
          <Stack
            sx={QuestionDescriptorStyle}
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            flexShrink={0}
          >
            <Typography fontWeight={500} fontSize={16}>
              {index + 1}
            </Typography>
            {Icon && <Icon />}
          </Stack>

          <Box sx={{ flex: 1, minWidth: "0px" }}>
            <Typography
              fontSize={16}
              fontWeight={500}
              sx={question?.properties?.required ? requiredStyles : {}}
            >
              {question.label}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={500}
              sx={{ color: "#6B7280" }}
            >
              ({question.varname})
              {question?.dependency && (
                <DependencyTextPreview dep={question?.dependency} />
              )}
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" alignItems={"center"}>
          {question.type !== "statement" && (
            <Tooltip title={showChoices ? "Hide Preview" : "Show Preview"}>
              <IconButton onClick={() => setShowChoices((prev) => !prev)}>
                {showChoices ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={editable ? "Edit" : "View Details"}>
            <IconButton onClick={handleEditQuestion}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          {editable && (
            <Tooltip title={"Duplicate"}>
              <IconButton onClick={handleCopyQuestion}>
                <DuplicateIcon />
              </IconButton>
            </Tooltip>
          )}

          {editable && (
            <Tooltip title={"Delete"}>
              <IconButton
                onClick={handleDeleteQuestionPopup}
                disabled={formSubmitting}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        {editable && (
          <DeleteConfirmModal
            title={question.label}
            onOk={handleDeleteQuestion}
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          />
        )}
      </Stack>

      {Component && showChoices && (
        <Stack gap={2} sx={{ width: "85%", pl: "92px" }}>
          {question?.signedImageUrl && (
            <Box sx={{ maxHeight: 97, maxWidth: 192, borderRadius: "8px" }}>
              <img
                src={question.signedImageUrl}
                alt="preview"
                style={{
                  maxHeight: "97px",
                  maxWidth: "192px",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
            </Box>
          )}
          <Component currentQuestion={question} />
        </Stack>
      )}
    </Stack>
  );
};

export default memo(QuestionItemWrapper);

const symbolMap: Record<string, string> = {
  eq: "is equal to",
  not_eq: "is not",
  gt: "greater than",
  lt: "less than",
  gte: "greater than or equal to",
  lte: "less than or equal to",
  is_checked: "is checked",
  is_not_checked: "is not checked",
};

const keyMap: Record<string, string> = {
  text: "textValue",
  date: "textValue",
  number: "numberValue",
  slider: "numberValue",
  calculated_field: "numberValue",
  radio: "questionChoice",
  dropdown: "questionChoice",
  checkbox: "questionChoice",
};

const DependencyTextPreview = ({ dep }: { dep: any }) => {
  const dependent = dep?.parentQuestion?.varname;
  const operator = symbolMap[dep?.operator];
  const type = dep?.parentQuestion?.type;
  const label = ["radio", "dropdown", "checkbox"].includes(type)
    ? dep?.[keyMap[type]]?.label
    : dep?.[keyMap[type]];

  return (
    <>
      {type === "checkbox" ? (
        <Typography
          fontSize={12}
          fontWeight={500}
          sx={{ color: "#6B7280" }}
          component={"span"}
        >
          &nbsp;&nbsp;|&nbsp;&nbsp; ({label} in {dependent}
          &nbsp;
          <strong>{operator}</strong>)
        </Typography>
      ) : (
        <Typography
          fontSize={12}
          fontWeight={500}
          sx={{ color: "#6B7280" }}
          component={"span"}
        >
          &nbsp;&nbsp;|&nbsp;&nbsp; ({dependent} <strong>{operator}</strong>{" "}
          {label})
        </Typography>
      )}
    </>
  );
};
