import { reset as adminReset } from "../reducers/administratorsSlice";
import { reset as partReset } from "../reducers/participantsSlice";
import { reset as userReset } from "../reducers/userSlice";
import { AppThunk } from "../store";

export const resetState = (): AppThunk => (dispatch) => {
  dispatch(partReset());
  dispatch(userReset());
  dispatch(adminReset());
};
