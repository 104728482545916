import { Stack, TextField } from "@mui/material";

type Props = {
  currentQuestion: any;
};

const TextFieldPreview = ({ currentQuestion }: Props) => {
  return (
    <Stack gap={1}>
      <TextField name="textValue" fullWidth placeholder="" />
    </Stack>
  );
};
export default TextFieldPreview;
